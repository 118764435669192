import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelection } from '../../store/generateOptionsSlice';
import { updateConversation } from '../../store/contentSlice';
import SelectTemplate from '../../components/templates/SelectTemplate';
import SelectModel from '../../components/models/SelectModel';
import { selectTemplate } from '../../store/templateSlice';
import ChatHistory from './ChatHistory';

import {
  Box,
  VStack,
  Stack,
  Card,
  CardBody,
  Image,
  Heading,
  Text,
  Button,
  Center,
  Flex
} from '@chakra-ui/react';


export default function SideBar() {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.generateOptions.model);
  const template = useSelector((state) => state.templates.template);
  const selection = useSelector((state) => state.generateOptions.selection);
  const conversation = useSelector((state) => state.content.conversation)

  const CurrentModel = () => {
    return (
      <Card
        key={model.title}
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        height='270px'
        width='310px'
        boxShadow='md'
      >
        <Stack>
          <Image
              objectFit={'cover'}
              maxW='310px'
              minH='100px'
              maxH='180px'
              src={`/images/${model.icon}`}
              alt={model.title}
          />
            <CardBody>
              <Center direction='column'>
                <Heading size='sm'>Generating with {model.title}</Heading>
                  <Text py='2' fontSize='xs'>
                  </Text>
                  <SelectModel />
              </Center>
            </CardBody>
        </Stack>
      </Card>
    )
  }

  const removeTemplate = () => {
    dispatch(setSelection('Prompt'));
    dispatch(selectTemplate({}));
  }

  const CurrentTemplate = () => {
    return (
      <Card
        key={model.title}
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        width='310px'
        boxShadow='md'
        >
        <Stack>
            <CardBody>
              <Flex direction={'column'} gap={4}>
                <Heading size='xs'>{template.name ? `${template.name}` : 'No template selected'}</Heading>
                <Text py='2' fontSize='xs'>
                  {template.description}
                </Text>
                <Flex gap={'10px'}>
                  <SelectTemplate />
                  {selection === 'Templates' ?  
                  <Button
                    onClick={removeTemplate}
                    variant='outline' 
                    colorScheme='blue'
                    w='130px'
                    size='xs'
                    p={4}
                    pl={8}
                    pr={8}
                  >
                    Remove Template
                  </Button>
                  : null}
                </Flex>
              </Flex>
            </CardBody>
        </Stack>
      </Card>
    )
  }

  const NewChat = () => {
    if(conversation.length < 1){
      return;
    }

    return (
      <Button 
          onClick={() => {dispatch(updateConversation([3]))}}
          variant='outline' 
          colorScheme='blue'
          w='200px'
          size='md'
        >
          New Conversation
        </Button>
    )
  }


  return (
    <Box
      backgroundColor='#fff'
      borderRight="1px"
      borderRightColor={'#f5f6fa'}
      w={'350px'}
      minW={'350px'}
      h="100%"
      overflow='scroll'
      pt='20px'
    >
      {/* <Flex
      direction='column'
      mt='30px'
      mb='40px'
      ml='20px'
      gap='20px'
      >
      <Flex 
        align='center' 
        >
        <Icon
            mr="4"
            fontSize="15"
            as={HiOutlineCode}
          />
        <Link onClick={handleClick} fontSize='15px'>
          Prompt
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={TfiWrite}
          />
        <Link onClick={()=>{window.location.href='/generate/copy'}} fontSize='15px'>
          Text
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={PiChatsLight}
          />
        <Link onClick={()=>{window.location.href='/generate/chat'}} fontSize='15px'>
          Chat
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={BiUserVoice}
          />
        <Link onClick={()=>{window.location.href='/generate/voice'}} fontSize='15px'>
          Voice
        </Link>
      </Flex>
      <Flex 
        align='center' 
      >
        <Icon
            mr="4"
            fontSize="15"
            as={ImImages}
          />
        <Link onClick={()=>{window.location.href='/generate/image'}} fontSize='15px'>
          Image
        </Link>
        
      </Flex>
    </Flex> */}
    
    <VStack gap='10px' align={'center'}>
        <CurrentModel />
        {/* <CurrentTemplate /> */}
        <ChatHistory />
        <NewChat />
    </VStack>
  </Box>
  );
}