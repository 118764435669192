import {
    Card,
    CardBody,
    Text,
    Stack,
    SimpleGrid,
    Flex,
    Heading
  } from '@chakra-ui/react';
  
  import TabView from '../components/documents/FoldersItems';
  
  import { HiOutlineDocumentAdd } from 'react-icons/hi'
  import { BiImageAdd } from 'react-icons/bi'
  import { PiChatsLight, PiSpeakerHigh } from 'react-icons/pi'
  
  import { useNavigate } from 'react-router-dom';
  import { useEffect, useState } from 'react';
    
  
  export default function Account() {
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
      
    useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth)
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    if(width <= 900){
        return (
            <Flex
                justify={'center'}
                align={'center'}
                p={'20px'}
                >
                <Heading size={'sm'}>
                    Sorry we don't currently support screen sizes below 900px
                </Heading>
            </Flex>
        )
    }
  
    const NewItem = ({icon, color, title, text, navigateTo}) => {
      return (
        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
          width='100%'
          boxShadow='md'
          as='button'
          maxW={'260px'}
          minW={'200px'}
          onClick={()=>{
            navigate(navigateTo);
          }}
          _hover={{
            boxShadow: "lg",
          }}
          transition="all 0.2s"
        >
          <Stack>
            <CardBody p={3}>
              <Flex direction={'row'} align={'center'} justify={'center'}>
                <Flex mr='10px' ml='5px'>
                  {icon}
                </Flex>
                <Flex direction={'column'} pt={2}>
                  <Heading size='sm' align={'left'}>{title}</Heading>
                  <Text py='2' fontSize='xs' mt={'-5px'} align={'left'}>
                    {text}
                  </Text>
                </Flex>
              </Flex>
            </CardBody>
          </Stack>
        </Card>
      )
    }
  
    const itemList = [
        {
          key: 'new-doc',
          icon: <HiOutlineDocumentAdd fontSize={'32px'} color={'rgb(255, 156, 102)'}/>,
          title: 'New Document',
          text: 'Generate a Text/Copy document',
          navigateTo: '/generate/copy'
        },
        {
          key: 'new-audio',
          icon: <PiSpeakerHigh fontSize={'32px'} color={'rgb(81, 72, 215)'}/>,
          title: 'New Voice Audio',
          text: 'Generate a new audio recording',
          navigateTo: '/generate/voice'
        },
        {
          key: 'new-image',
          icon: <BiImageAdd fontSize={'32px'} color={'rgb(102,159,42)'}/>,
          title: 'New Image',
          text: 'Generate a new image',
          navigateTo: '/generate/image'
        },
        {
          key: 'new-chat',
          icon: <PiChatsLight fontSize={'32px'} color={'#7700FF'}/>,
          title: 'New Chat',
          text: 'Start a conversation',
          navigateTo: '/generate/chat'
        },
      ]
  
    return (
      <Flex 
        direction={'column'} 
        gap={'40px'} 
        maxW={'2000px'}
        w={'100%'} 
        h={'100%'}
        align={'center'} 
        px={['20px', '50px']} 
        bgColor={'rgb(249,250,251)'}
      >
        <SimpleGrid columns={[1, 2, 4]} spacing={5} mt='40px'>
          {itemList.map(item => <NewItem {...item}/>)}
        </SimpleGrid>
        <TabView />
      </Flex>
    );
  }
  