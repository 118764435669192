import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsLoadingVEOWriter } from '../../store/contentSlice';

import { Flex, Button, ButtonGroup, Text, useDisclosure } from '@chakra-ui/react';
import { CopyIcon, DownloadIcon, WarningIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import SelectPublish from '../../components/publish/SelectPublish';
import AgentDocumentModal from './Documents'; // Import the AgentDocumentModal component
import { FaRegCircleStop } from "react-icons/fa6";
import axios from 'axios'

const ENV = process.env.NODE_ENV;
const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';

const DocumentActions = ({ content, disable }) => {
    const toast = useToast();
    // Copy to Clipboard
    const handleCopy = async () => {
        const htmlContainer = document.getElementById('veoWriterHTMLContainer');
        if (htmlContainer) {
            try {
                // Serialize the HTML content
                const htmlContent = htmlContainer.outerHTML;

                // Create a Blob with the HTML content
                const blob = new Blob([htmlContent], { type: 'text/html' });
                const data = [new ClipboardItem({ 'text/html': blob })];

                // Use Clipboard API to write HTML content
                await navigator.clipboard.write(data);
                
                // Show success toast
                toast({
                    title: "Content copied!",
                    description: "The content was successfully copied to the clipboard.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (err) {
                // Show error toast
                toast({
                    title: "Failed to copy.",
                    description: "Failed to copy content to clipboard.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });

                console.error('Failed to copy content to clipboard.', err);
            }
        }
    };

    // Download Content
    const handleDownload = () => {
        try {
            const htmlContainer = document.getElementById('veoWriterHTMLContainer');
            if (htmlContainer) {
                // Serialize the HTML content
                const htmlContent = htmlContainer.outerHTML;

                // Parse the HTML to find the first H1 text content
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, 'text/html');
                const firstH1 = doc.querySelector('h1');
                const title = firstH1 ? firstH1.textContent.trim().replace(/\s+/g, '_') : 'untitled';

                const element = document.createElement("a");
                const file = new Blob([htmlContent], { type: 'text/html' });
                element.href = URL.createObjectURL(file);
                element.download = `${title}.html`;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                // Show success toast
                toast({
                    title: "Download started!",
                    description: `Download has started successfully.`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (err) {
            // Show error toast
            toast({
                title: "Failed to download.",
                description: "An error occurred while trying to download.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });

            console.error('Failed to download content.', err);
        }
    };

    let disableButtons = content.length ? false : true;

    return (
        <ButtonGroup isAttached variant="outline">
            <Button isDisabled={disableButtons || disable} onClick={handleDownload} leftIcon={<DownloadIcon />} borderColor="gray.300" bg="gray.200" _hover={{ bg: "gray.300" }}>
                Download
            </Button>
            <Button isDisabled={disableButtons || disable} onClick={handleCopy} leftIcon={<CopyIcon />} borderColor="gray.300" bg="gray.200" _hover={{ bg: "gray.300" }}>
                Copy
            </Button>
        </ButtonGroup>
    )
}

const Stop = ({id})=> {
    const activeTasks = useSelector((state) => state.content.activeTasks);
    const isLoadingVEOWriter = useSelector((state) => state.content.isLoadingVEOWriter);
    const dispatch = useDispatch();

    const actionName = activeTasks.veowriter;
    const handleStop = async () => {
        if(isLoadingVEOWriter){
            dispatch(updateIsLoadingVEOWriter(false));
        }
        try {
          await axios.post(
            `${api_url}/stop`,
            { actionName }, // Replace 'VeoWriter' with the appropriate action name if necessary
            {
              withCredentials: true
            }
          );
        } catch (error) {
          console.error('Error handling stop request:', error);
        }
      };

    return(
        <Button isDisabled={!isLoadingVEOWriter} onClick={handleStop} leftIcon={<FaRegCircleStop />} borderColor="gray.300" bg="gray.200" _hover={{ bg: "gray.300" }}>
            Stop
        </Button>
    )
}

const DocumentOptions = ({ content, length }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const disable = useSelector((state) => state.content.isLoadingVEOWriter);
    const isLoadingVEOWriter = useSelector((state) => state.content.isLoadingVEOWriter);

    return (
        <Flex h="55px" justify="space-between" align="top" gap="5px">
            <Flex justify="left" gap="10px">
                <DocumentActions content={content} disable={disable}/>
                <SelectPublish content={content} disable={disable}/>
                <Button onClick={onOpen} borderColor="gray.300" bg="gray.200" _hover={{ bg: "gray.300" }} isDisabled={disable}>
                    Documents
                </Button>
                {isLoadingVEOWriter && <Stop />}
                <AgentDocumentModal isOpen={isOpen} onClose={onClose} agent={'seo-writer'}/> 
            </Flex>
            <Flex color="white" justify="left" mt={"10px"} mr={'50px'} minW={"140px"}>
                <Text
                    fontSize='sm'
                    color='gray.100'
                    as='b'
                >
                    {content.length ? `Word Count: ${length}` : ''}
                </Text>
            </Flex>
        </Flex>
    );
}

export default DocumentOptions;