import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

function PriceWrapper(props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

export default function PaymentPrompt() {
  const user = useSelector((state) => state.user.activeUser);
  const [isLoading, setIsLoading] = useState({});

  useEffect(() => {
    // Ensure you have a valid user object with an `_id` property
    mixpanel.identify(user._id);

    window.gtag('event', 'view-subscription-widget');

    mixpanel.track('Payments shown', {
      'Stage': 'View-widget'
    });
  }, []); // Empty dependency array so it only runs once after the component mounts.

  async function subscribeUser(subscriptionPackage) {
    try {
      const response = await fetch(`${api_url}/user/subscribe`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({subscriptionPackage})
      });
  
      const result = await response.json();
      
      if (response.ok) {
        console.log(`User subscribed successfully: ${JSON.stringify(result)}`);
        // Process the response here
        return result.checkoutSession;
      } 
      else {
        console.error(`Failed to subscribe user: ${JSON.stringify(result)}`);
       // handleError(result);
      }
    } catch (error) {
      console.error(`Failed to subscribe user: ${error}`);
      // If there was a networking error let's notify user about it.
      // handleError(error);
    }
  }

  const handleClick = async (packageName) => {
    window.gtag('event', `click-subscription-${packageName}`);
    mixpanel.track('Payments clicked', {
      'Stage': `Click-${packageName}`
    })
    let newLoadingState = {};
    newLoadingState[packageName] = true;

    setIsLoading(newLoadingState);

    try {
      const checkoutUrl = await subscribeUser(packageName);
      var win = window.open(checkoutUrl, '_self');
      win.focus();
    } catch (error) {
      console.error("Error during subscription: ", error);
    }
  }

  return (
    <Box py={12}>
      <VStack textAlign="center">
        <Heading as="h1" fontSize="4xl" mb='10px'>
          Oops... Looks like you've run out of credits 😮
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          But don't worry, we have a few options for you...
        </Text>
        <Text fontSize="lg" color={'gray.500'}>
          To make it simple, pay monthly and cancel anytime ✌️
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
        mt={'10px'}
        >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Essentials
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                9
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                25 Credits per month
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Voice, Image, Written Copy
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                GPT 3.5 & GPT 4.0
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                SDXL 1.0 & Dalle 3
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Over 70 templates
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Chat functionality
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                1 GB document storage
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="blue" variant="outline" onClick={()=>{handleClick('price_1OBRPkJoMbApBkqKcDp3szcg')}} isLoading={isLoading.price_1OBRPkJoMbApBkqKcDp3szcg}>
                Get Started
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('blue.500', 'blue.700')}
                px={3}
                py={1}
                color={useColorModeValue('gray.100', 'gray.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Most Popular
              </Text>
            </Box>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Starter
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  29
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                100 Credits per month
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Voice, Image, Written Copy
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                GPT 3.5 & GPT 4.0
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                GPT 4.0 Turbo
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                GPT 4.0 32K Context
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                SDXL 1.0 & Dalle 3
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Over 70 templates
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Chat functionality
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                2 GB document storage
              </ListItem>
            </List>
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="blue" onClick={() => {handleClick('price_1OBQgqJoMbApBkqK6vhmN620')}} isLoading={isLoading.price_1OBQgqJoMbApBkqK6vhmN620}>
                  Get Started
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Professional
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                49
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                200 Credits per month
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Voice, Image, Written Copy
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Access all Veo LLMs
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                SDXL 1.0 & Dalle 3
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Over 70 templates
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Chat functionality
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                5 GB document storage
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="blue" variant="outline" onClick={()=>{handleClick('price_1OBRXcJoMbApBkqKxgOSHDUm')}} isLoading={isLoading.price_1OBRXcJoMbApBkqKxgOSHDUm}>
                Get Started
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}