import React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { 
    Card, 
    Image,
    CardBody,
    Stack,
    Heading,
    CardFooter,
    Button,
    Flex,
    Text,
    Badge,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    List,
    ListItem,
    Checkbox,
    CheckboxGroup
} from '@chakra-ui/react';
import {
    ChevronDownIcon
} from '@chakra-ui/icons'
import ModelDetail from '../components/ModelDetail';
import { useDispatch } from 'react-redux';
import { setModel } from '../store/generateOptionsSlice';
import { useSelector } from 'react-redux';
import { retrieveModels } from "../data/models";
import { updateModelList, loaded } from '../store/modelSlice';
import Loading from '../components/Loading';

function Models(){

    let [selections, setSelections] = useState({
        providers: ['OpenAi','Anthropic','Cohere','Stability.ai','Eleven labs'],
        features: ['Chat','Completions','Text to image','Text to voice','Voice to text','Speech to text'],
        contentTypes: ['Voice','Image','Text','Chat','Transcription']
    });

    const modelList = useSelector((state) => state.models.modelList);
    const modelListLoaded = useSelector((state) => state.models.loadedModelList);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { innerWidth: width} = window;

    if(width <= 900){
        return (
            <Flex
                justify={'center'}
                align={'center'}
                p={'20px'}
                >
                <Heading size={'sm'}>
                    Sorry we don't currently support screen sizes below 900px
                </Heading>
            </Flex>
        )
    }
    
    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
        
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        
        return array;
    }

    if(!modelListLoaded) {
        retrieveModels().then(modelList => {
            shuffle(modelList);
            dispatch(updateModelList(modelList));
            dispatch(loaded());
        });
        return <Loading />
    }

    const handleFilterChange = (e)=>{
        const value = e.target.labels[0].innerText;
        const checked = e.target.checked;
        const category = e.target.labels[0].dataset.category;
        if(checked){
            const updatedSelection = [...selections[category],value]
            setSelections({...selections, [category]: updatedSelection});
        } else {
            const index = selections[category].indexOf(value);
            if(index > -1){
                const updatedSelection = [...selections[category]]
                updatedSelection.splice(index,1);
                setSelections({...selections, [category]: updatedSelection});
            }
        }
    };

    const filters = (
        <Flex mt='40px' mb='20px' pl='40px' justify='left' gap='40px' >
            <Popover placement='top-start' >
                <PopoverTrigger >
                    <Button
                        variant='outline'
                        _hover={{ bg: '#fff' }}
                    >Provider<ChevronDownIcon boxSize='2rem' pl='10px' color='gray.500'/></Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                        <CheckboxGroup>
                            <List>
                                <ListItem><Checkbox data-category='providers' defaultChecked mr='10px' onChange={handleFilterChange}>OpenAi</Checkbox></ListItem>
                                <ListItem><Checkbox data-category='providers' defaultChecked mr='10px' onChange={handleFilterChange}>Anthropic</Checkbox></ListItem>
                                <ListItem><Checkbox data-category='providers' defaultChecked mr='10px' onChange={handleFilterChange}>Stability.ai</Checkbox></ListItem>
                                <ListItem><Checkbox data-category='providers' defaultChecked mr='10px' onChange={handleFilterChange}>Cohere</Checkbox></ListItem>
                                <ListItem><Checkbox data-category='providers' defaultChecked mr='10px' onChange={handleFilterChange}>Eleven labs</Checkbox></ListItem>
                            </List>
                        </CheckboxGroup>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            <Popover placement='top-start' >
                <PopoverTrigger >
                    <Button
                        variant='outline'
                        _hover={{ bg: '#fff' }}
                    >Features<ChevronDownIcon boxSize='2rem' pl='10px' color='gray.500'/></Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                    <List>
                        <ListItem><Checkbox data-category='features' defaultChecked mr='10px' onChange={handleFilterChange}>Chat</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='features' defaultChecked mr='10px' onChange={handleFilterChange}>Chat</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='features' defaultChecked mr='10px' onChange={handleFilterChange}>Text generation</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='features' defaultChecked mr='10px' onChange={handleFilterChange}>Text to image</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='features' defaultChecked mr='10px' onChange={handleFilterChange}>Text to voice</Checkbox></ListItem>
                        <ListItem><Checkbox data-category='features' defaultChecked mr='10px' onChange={handleFilterChange}>Speech to text</Checkbox></ListItem>
                    </List>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            <Popover placement='top-start' >
                <PopoverTrigger >
                    <Button
                        variant='outline'
                        _hover={{ bg: '#fff' }}
                    >Content types<ChevronDownIcon boxSize='2rem' pl='10px' color='gray.500'/></Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                        <List>
                            <ListItem><Checkbox data-category='contentTypes' defaultChecked mr='10px' onChange={handleFilterChange}>Voice</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='contentTypes' defaultChecked mr='10px' onChange={handleFilterChange}>Image</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='contentTypes' defaultChecked mr='10px' onChange={handleFilterChange}>Text</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='contentTypes' defaultChecked mr='10px' onChange={handleFilterChange}>Chat</Checkbox></ListItem>
                            <ListItem><Checkbox data-category='contentTypes' defaultChecked mr='10px' onChange={handleFilterChange}>Transcription</Checkbox></ListItem>

                        </List>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Flex>
    )
    
    const renderedModels = modelList.map(model => {
        let filtered = false;
        let filteredAbilities = false;
        let filteredContentTypes = false;

        if(!selections.providers.includes(model.manufacturer)){
            filtered = true;
        } 

        if(filtered){
            return;
        }

        const renderedAbilities = model.abilities.map(ability => {
            if(selections.features.includes(ability)) {
                filteredAbilities = true;
            }
            return <Badge variant='solid' mt='20px' mr='10px' key={ability}>{ability}</Badge>
        });

        if(!filteredAbilities){
            return;
        }

        model.generates.map(type => {
            if(selections.contentTypes.includes(type)) {
                filteredContentTypes = true;
            }
        });

        if(!filteredContentTypes){
            return;
        }

        let generateLink = '/generate/copy';

        if(model.abilities.includes('Image generation')){
            generateLink = '/generate/image';
        }

        if(model.abilities.includes('Text to voice')){
            generateLink = '/generate/voice';
        }

        if(model.abilities.includes('Voice to text')){
            generateLink = '/generate/transcription';
        }
        
        const Chat = () => {
            if(model.abilities.includes('Chat')){
                return (
                    <Button 
                        variant='solid'
                        colorScheme='blue' 
                        mr='15px'
                        w='90px'
                        size='sm'
                        onClick={() => {dispatch(setModel(model)); navigate('/generate/chat')}}
                    >
                        Chat
                    </Button>
                )
            }
        };

        return <Card
            key={model.title}
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            min-height='250px'
            width='650px'
            margin='30px'
            boxShadow='md'
        >
            <Image
                objectFit='cover'
                maxW={{ base: '100%', sm: '200px' }}
                src={`/images/${model.icon}`}
                alt={model.title}
            />
            <Stack>
                <CardBody>
                    <Heading size='sm'>{model.title}</Heading>
                    <Text py='2' fontSize='sm'>
                    {model.description}
                    </Text>
                    {renderedAbilities}
                </CardBody>
                <CardFooter justify='right' pt='0px'>
                    <Button variant='solid' colorScheme='blue' mr='15px' w='90px' size='sm' onClick={() => {dispatch(setModel(model)); navigate(generateLink)}}>
                        Generate
                    </Button>
                    <Chat />
                    <ModelDetail model={model}/>
                </CardFooter>
            </Stack>
        </Card>
    })
    
    return (
        <Flex direction="column" sx={{userSelect: 'none'}}>
            {filters}
            <Flex wrap='wrap' justify='center'>
                {renderedModels}
            </Flex>
        </Flex>
    )
}

export default Models;