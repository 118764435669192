import axios from 'axios'
import noUser from '../utils/HandleNoUser';

// import mixpanel from 'mixpanel-browser';
// Set this to a unique identifier for the user performing the event.
//mixpanel.identify('user123')
 
// Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
// mixpanel.track('Sign up', {
//   'Signup Type': 'Referral'
// })

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'


const user = {
  register: async (data) => {
    return await axios.post(
      `${api_url}/user/register/`,
      data,
      {withCredentials: true}
      ).then((response) => {
        if(response.data === 'no user'){
          noUser();
        } else {
          return response.data;
        }
    }).catch(err => console.log(err))
  },
  authenticate: async (data) => {
    return await axios.post(
      `${api_url}/user/authenticate/`,
      data,
      {withCredentials: true}
      ).then((response) => {
        console.log('authenticate response: ',response);
        if(response.data === 'no user'){
          console.log('no user')
          //noUser();
        } else {
          return response.data;
        }
    }).catch(err => console.log(err))
  },
  get: async () => {
    return await axios.get(
      `${api_url}/user`,
      {withCredentials: true}
      ).then((user) => {
        console.log('get user response: ',user);
        if(user.data === 'no user'){
          noUser();
        } else {
          return user.data;
        }
    }).catch(err => console.log(err))
  },
  logout: async () => {
    return await axios.post(
      `${api_url}/user/logout`,
      {withCredentials: true}
      ).then((data) => {
        if(data.data === 'no user'){
          noUser();
        } else {
        return data;
        }
    }).catch(err => console.log(err))
  },
  requestPasswordReset: async (data) => {
    return await axios.post(
      `${api_url}/user/request-password-reset`,
      data,
      {withCredentials: true}
      ).then((data) => {
        if(data.data === 'no user'){
          noUser();
        } else {
        return data;
        }
    }).catch(err => console.log(err))
  },
  PasswordReset: async (data) => {
    return await axios.post(
      `${api_url}/user/password-reset`,
      data,
      {withCredentials: true}
      ).then((data) => {
        if(data.data === 'no user'){
          noUser();
        } else {
        return data;
        }
    }).catch(err => console.log(err))
  }
}

export default user;