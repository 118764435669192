import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { 
    Card, 
    Image,
    CardBody,
    Stack,
    Heading,
    CardFooter,
    Button,
    Flex,
    Text,
    Badge,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    List,
    ListItem,
    Checkbox,
    CheckboxGroup
} from '@chakra-ui/react';
import {
    ChevronDownIcon
} from '@chakra-ui/icons'
import ModelDetail from '../components/ModelDetail';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { retrieveAgents } from "../data/agents";
import { updateAgentList, loaded } from '../store/agentSlice';
import Loading from '../components/Loading';

function Agents(){

  let [selections, setSelections] = useState({
      providers: ['OpenAi','Anthropic','Cohere','Stability.ai','Eleven labs'],
      features: ['Chat','Completions','Text to image','Text to voice','Voice to text','Speech to text'],
      contentTypes: ['Voice','Image','Text','Chat','Transcription']
  });

  const agentList = useSelector((state) => state.agents.agentList);
  const agentListLoaded = useSelector((state) => state.agents.loadedModelList);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { innerWidth: width} = window;
  
  function shuffle(array) {
      let currentIndex = array.length,  randomIndex;
      
      // While there remain elements to shuffle.
      while (currentIndex > 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      
      return array;
  }

  useEffect(() => {
    // This will run only once when the component mounts.
    if (!agentListLoaded) {
        retrieveAgents().then(agentList => {
            //const shuffledAgents = shuffle(agentList);
            dispatch(updateAgentList(agentList));
            dispatch(loaded());
        });
    }
  }, [agentListLoaded, dispatch]); // Dependecies array

  if(width <= 900){
    return (
        <Flex
            justify={'center'}
            align={'center'}
            p={'20px'}
            >
            <Heading size={'sm'}>
                Sorry we don't currently support screen sizes below 900px
            </Heading>
        </Flex>
    )
  }
  
  const handleFilterChange = (e)=>{
      const value = e.target.labels[0].innerText;
      const checked = e.target.checked;
      const category = e.target.labels[0].dataset.category;
      if(checked){
          const updatedSelection = [...selections[category],value]
          setSelections({...selections, [category]: updatedSelection});
      } else {
          const index = selections[category].indexOf(value);
          if(index > -1){
              const updatedSelection = [...selections[category]]
              updatedSelection.splice(index,1);
              setSelections({...selections, [category]: updatedSelection});
          }
      }
  };

    
  const renderedModels = agentList.map(agent => {

    const renderedAbilities = agent.abilities.map(ability => {
        return <Badge variant='solid' mt='20px' mr='10px' key={ability}>{ability}</Badge>
    });

    let generateLink = `/agents/${agent.path}`;
    
    const Chat = () => {
        if(agent.abilities.includes('Chat')){
            return (
                <Button 
                    variant='solid'
                    colorScheme='blue' 
                    mr='15px'
                    w='90px'
                    size='sm'
                    onClick={() => {navigate('/generate/chat')}}
                >
                    Chat
                </Button>
            )
        }
    };

    return <Card
        key={agent.title}
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        min-height='250px'
        width='650px'
        margin='30px'
        boxShadow='md'
    >
        <Image
            objectFit='cover'
            maxW={{ base: '100%', sm: '200px' }}
            src={`/images/${agent.icon}`}
            alt={agent.title}
        />
        <Stack>
            <CardBody>
                <Heading size='sm'>{agent.title}</Heading>
                <Text py='2' fontSize='sm'>
                {agent.description}
                </Text>
                {renderedAbilities}
            </CardBody>
            <CardFooter justify='right' pt='0px'>
                <Button variant='solid' colorScheme='blue' mr='15px' w='90px' size='sm' onClick={() => {navigate(generateLink)}}>
                    Generate
                </Button>
                <Chat />
                <ModelDetail model={agent}/>
            </CardFooter>
        </Stack>
    </Card>
  })

  return (
    <Flex direction="column" sx={{userSelect: 'none'}}>
        <Flex wrap='wrap' justify='center' pt='95px'>
            {renderedModels}
        </Flex>
    </Flex>
  )
}

export default Agents;