import React, { useState, useEffect } from 'react';
import {
  Button,
  useToast,
  Flex,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  Spinner,
  Heading,
  Center,
  HStack,
  Spacer
} from "@chakra-ui/react";

import { VscSettings } from "react-icons/vsc";
import { DeleteIcon } from "@chakra-ui/icons";

const ENV = process.env.NODE_ENV;
const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai';

const SelectSettings = ({ loadSettings }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleting, setDeleting] = useState({});
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      fetchSettings();
    }
  }, [isOpen]);

  const fetchSettings = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`${api_url}/settings/seo-writer`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch settings');
      }

      const data = await response.json();
      setSettings(data);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSetting = async (settingId) => {
    try {
      setDeleting((prev) => ({ ...prev, [settingId]: true }));

      const response = await fetch(`${api_url}/settings/${settingId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('Failed to delete setting');
      }

      // Optimistically update UI
      setSettings((prevSettings) => prevSettings.filter(setting => setting._id !== settingId));

      toast({
        title: 'Success',
        description: 'Setting successfully deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDeleting((prev) => ({ ...prev, [settingId]: false }));
    }
  };

  return (
    <Flex alignItems="center" p={0} m={0}>
      <Flex alignItems="center" p={0} m={0}>
        <Button
          onClick={onOpen}
          fontSize='sm'
          _hover='none'
          w={'180px'}
          variant="outline"
          colorScheme="blue"
          borderColor="gray.400"
          leftIcon={<VscSettings />}
          p={0} m={0}
        >Load Presets</Button>
      </Flex>
      <SettingsPopup
        isOpen={isOpen}
        onClose={onClose}
        settings={settings}
        isLoading={isLoading}
        deleting={deleting}
        deleteSetting={deleteSetting}  // Pass the deleteSetting function as a prop
        loadSettings={loadSettings} // Pass the loadSettings function as a prop
      />
    </Flex>
  );
};

const SettingsPopup = ({
  isOpen,
  onClose,
  settings,
  isLoading,
  deleting,
  deleteSetting, // Receive deleteSetting as a prop
  loadSettings, // Receive loadSettings as a prop
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minHeight="500px">
        <ModalHeader>Presets</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex align="center" justify="center" height="100%" direction="column" gap="30px" marginTop="20px">
              <Heading>Loading Presets...</Heading>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Flex>
          ) : (
            <VStack maxHeight="400px" overflowY="auto" spacing={3} align="stretch">
              {settings.length ? (
                settings.map((setting, index) => (
                  <HStack
                    key={setting._id}
                    borderWidth="1px"
                    borderRadius="md"
                    padding={3}
                    width="100%"
                    onClick={() => {loadSettings({...setting.json,targetKeyword: ''});onClose()}} // Pass the JSON field to loadSettings on click
                    cursor="pointer" // Change cursor to pointer to indicate clickability
                    _hover={{ bg: "gray.100" }} // Add hover effect
                  >
                    <Text>{setting.name}</Text>
                    <Spacer />
                    <Flex width="40px" justify="space-between" h={'40px'}>
                      {deleting[setting._id] ? (
                        <Center w='100%'>
                          <Spinner size="sm" color="blue.500" margin={'auto'} />
                        </Center>
                      ) : (
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Delete Setting"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the click from propagating to the HStack
                            deleteSetting(setting._id);
                          }}
                        />
                      )}
                    </Flex>
                  </HStack>
                ))
              ) : (
                <Text>No settings available.</Text>
              )}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectSettings;