import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../components/chat/SideBar';
import { retrieveModels } from "../data/models";
import { retrieveTemplates } from "../data/templates";
import { updateModelList, loaded } from '../store/modelSlice';
import { setModel, setGenerateMedia } from '../store/generateOptionsSlice';
import { updateTemplates, templateIsLoaded, selectTemplate } from '../store/templateSlice';
import Loading from '../components/Loading';
import Chat from '../components/chat/Chat';
import { 
  Flex, 
  Heading,
} from '@chakra-ui/react'

function GenerateChat(){
  const dispatch = useDispatch();
  const models = useSelector((state) => state.models.modelList);
  const [loading, setLoading] = useState(true);
  const selectedModel = useSelector((state) => state.generateOptions.model);
  const templatesLoaded = useSelector((state) => state.templates.templatesLoaded);

  useEffect(() => {
    const fetchData = async () => {
      try {

        if(models.length <= 0) {
          const modelList = await retrieveModels();
          dispatch(updateModelList(modelList));
          dispatch(loaded());
        }

        let loadModel = true;

        if(selectedModel !== null){
          if(selectedModel.generates.includes('Chat')){
            loadModel = false;
          }
        }

        if(loadModel){
          models.forEach((m) => {
            if(m._id === '64ab27f9bd772e58bcb34ac3'){
              dispatch(setModel(m));
            }
          });
        }
        if(!templatesLoaded) {
          retrieveTemplates().then(templateList => {
              dispatch(updateTemplates(templateList));
              dispatch(templateIsLoaded());
              dispatch(selectTemplate(templateList));
              dispatch(setGenerateMedia('Text'));
          });
        }
        dispatch(setGenerateMedia('Text'));
        setLoading(false);
        
      } catch(error) {
        console.log('Error fetching data', error);
      }
    };
    fetchData();
  }, [dispatch, models]);

  if (loading) {
    return <Loading />;
  }

  const { innerWidth: width} = window;

  if(width <= 900){
      return (
          <Flex
              justify={'center'}
              align={'center'}
              p={'20px'}
              >
              <Heading size={'sm'}>
                  Sorry we don't currently support screen sizes below 900px
              </Heading>
          </Flex>
      )
  }
  
  return (
    <Flex 
      w='100%' 
      h='100%'
      bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
      >
        <SideBar />
        <Flex direction='column' m='20px' flexGrow={'1'}>
          <Chat />            
        </Flex>
    </Flex>      
  )
}

export default GenerateChat;