import React, { useState, useEffect } from 'react';
import {
  Button,
  Select,
  Flex,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  VStack,
  Text,
  HStack,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  useToast,
  Heading,
  Center,
} from "@chakra-ui/react";
import { DeleteIcon, DownloadIcon, AddIcon } from "@chakra-ui/icons";
import UploadFile from './UploadFile'; // Verify correct path

const DomainManager = ({ domains, addDomain, domain, setDomain, fetchDomains }) => {
  const [selectedDomain, setSelectedDomain] = useState(domain);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [xmlAddress, setXmlAddress] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [file, setFile] = useState(null); // New state for file input
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [deletingDomain, setDeletingDomain] = useState(null); // Deleting state
  const [downloadingDomain, setDownloadingDomain] = useState(null); // Downloading state
  const toast = useToast();

  useEffect(() => {
    setSelectedDomain(domain);
  }, [domain]);

  const handleAddDomain = async () => {
    setIsLoading(true); // Set loading state
    try {
      let response;
      
      if (file) {
        // Create FormData and append the file
        const formData = new FormData();
        formData.append('file', file);

        // Assuming your backend route is '/domainsSitemap/create'
        response = await fetch('/domainsSitemap/create', {
          method: 'POST',
          credentials: 'include', // Include credentials for authentication
          body: formData, // Use FormData as the body
        });
      } else {
        // Assuming your existing addDomain function handles non-file uploads
        response = await addDomain(xmlAddress); // Assuming addDomain returns response
      }

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Domain added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchDomains();
        setIsAdding(false); // Switch back to the domain list
      } else {
        const error = await response.json();
        toast({
          title: 'Error',
          description: error.message || 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Internal Server Error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Unset loading state
      setXmlAddress("");
      setFile(null);
    }
  };

  const handleDeleteDomain = async (domain) => {
    setDeletingDomain(domain); // Set deleting state
    try {
      const response = await fetch('/domainsSitemap/delete', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain }),
      });

      if (response.status === 200) {
        const result = await response.json();
        toast({
          title: 'Success',
          description: result.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchDomains();
      } else if (response.status === 404) {
        const result = await response.json();
        toast({
          title: 'Error',
          description: result.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error',
          description: 'Something went wrong',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Internal Server Error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDeletingDomain(null); // Unset deleting state
    }
  };

  const downloadCSV = async (domain) => {
    setDownloadingDomain(domain);
    try {
      const response = await fetch(`/domainsSitemap/csv/${domain}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.status === 200) {
        const csv = await response.text();
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${domain}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        const error = await response.json();
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to download CSV',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setDownloadingDomain(null);
    }
  };

  const downloadTemplateCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8,domain,url,h1,titleTag,metaDescription\nexample.com,https://www.example.com/page1,This is the first H1 for the page,This is the title provided in the title tage for the page,This is the full meta description for the page,\n";
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "template.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex w={'100%'} p={'0px'}>
      <Flex alignItems="left" w='100%' p={"0px"}>
        <Select
          w='440px'
          placeholder={domains.length === 0 ? 'No domains available' : ''}
          value={selectedDomain}
          onChange={(e) => {
            setSelectedDomain(e.target.value);
            setDomain(e.target.value);
          }}
          marginRight='20px'
        >
          {domains.map((domain) => (
            <option key={domain} value={domain}>
              {domain}
            </option>
          ))}
        </Select>
        <Button
          onClick={() => {
            onOpen();
            setIsAdding(false);
          }}
          fontSize='sm' // Adjust font size here
          bgColor='blue.500'
          color='white'
          _hover='none'
          w={'145px'}
        >Manage Domains</Button>
      </Flex>
      <DomainPopup
        isOpen={isOpen}
        onClose={onClose}
        domains={domains}
        deleteDomain={handleDeleteDomain}
        downloadCSV={downloadCSV}
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        xmlAddress={xmlAddress}
        setXmlAddress={setXmlAddress}
        handleAddDomain={handleAddDomain}
        setFile={setFile}
        file={file}
        downloadTemplateCSV={downloadTemplateCSV}
        isLoading={isLoading} // Pass loading state
        deletingDomain={deletingDomain} // Pass deleting state
        downloadingDomain={downloadingDomain} // Pass downloading state
      />
    </Flex>
  );
};

const DomainPopup = ({
  isOpen,
  onClose,
  domains,
  deleteDomain,
  downloadCSV,
  isAdding,
  setIsAdding,
  xmlAddress,
  setXmlAddress,
  handleAddDomain,
  setFile,
  file,
  downloadTemplateCSV,
  isLoading, // Receive loading state
  deletingDomain, // Receive deleting state
  downloadingDomain, // Receive downloading state
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="lg">
    <ModalOverlay />
    <ModalContent minHeight="500px">
      <ModalHeader>{isAdding ? "Add a New Domain" : "Manage Domains"}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {isLoading ? (
          <Flex align="center" justify="center" height="100%" direction="column" gap="30px" marginTop="20px">
            <Heading>Scanning domain...</Heading>
            <Text marginBottom="30px">This can take several minutes.</Text>
            <Spinner thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'/>
          </Flex>
        ) : isAdding ? (
          <Tabs variant="enclosed">
            <TabList>
              <Tab>XML Sitemap</Tab>
              <Tab>CSV Upload</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex width="100%" justify="center" direction="column" marginTop="20px">
                  <Text color="#505050" width="100%">
                    Enter the full URL for your XML sitemap:
                  </Text>
                  <Input
                      placeholder="https://www.domain.com/sitemap.xml"
                      value={xmlAddress}
                      onChange={(e) => setXmlAddress(e.target.value)}
                      mt={4}
                  />
                </Flex>
              </TabPanel>
              <TabPanel>
                <UploadFile setFile={setFile} file={file} downloadTemplateCSV={downloadTemplateCSV} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <>
            {domains.length ? (
              <VStack
                maxHeight="400px"
                overflowY="auto"
                spacing={3}
                align="stretch"
              >
                {domains.map((domain) => (
                  <HStack key={domain} borderWidth="1px" borderRadius="md" padding={3} width="100%">
                    <Text>{domain}</Text>
                    <Spacer />
                    <Flex width="90px" justify="space-between">
                      {downloadingDomain === domain ? (
                        <Center w='100%'>
                          <Spinner size="sm" color="blue.500" margin="auto"/>
                        </Center>
                      ) : (
                        <IconButton
                          icon={<DownloadIcon />}
                          aria-label="Download CSV"
                          onClick={() => downloadCSV(domain)}
                        />
                      )}
                      {deletingDomain === domain ? (
                        <Center w='100%'>
                          <Spinner size="sm" color="blue.500" margin="auto"/>
                        </Center>
                      ) : (
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Delete Domain"
                          onClick={() => deleteDomain(domain)}
                        />
                      )}
                    </Flex>
                  </HStack>
                ))}
              </VStack>
            ) : (
              <Text>No domains available</Text>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {isAdding ? (
          <>
            <Button colorScheme="blue" mr={3} onClick={handleAddDomain}>
              Scan
            </Button>
            <Button onClick={() => setIsAdding(false)}>Cancel</Button>
          </>
        ) : (
          <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={() => setIsAdding(true)}>
            Add Domain
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default DomainManager;