import user from '../data/user';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser, updateAuth, updateSubscriptionActive } from '../store/userSlice';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  Center
} from '@chakra-ui/react';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    username: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const [failedAuth, setFailedAuth] = useState(false);

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    setLoading(true);
    user.authenticate(values).then((res)=>{
      if(res[0]) {
        // Track an event. It can be anything, but in this example, we're tracking a Login event.
        mixpanel.identify(user._id);
        window.gtag('event', 'login');
        mixpanel.track('Login', {
          'Login Type': 'Beta'
        })
        dispatch(updateUser(res[1]));
        dispatch(updateAuth(true));
        // dispatch(updateSubscriptionActive(res[3]));
        setFailedAuth(false);
        //Store HMAC for intercom
        if(res[2]){
          const HMAC = window.HMAC = res[2];
          localStorage.setItem('HMAC', HMAC);
        }
        navigate('/models');
      } else {
        setFailedAuth(true);
      }
      setLoading(false);
    });
  }

  const handleChange = (e)=>{
    const value = e.target.value;
    const key = e.target.name;
    setValues({
        ...values,
        [key]: value
    })
  }

  const authError = (
    <Center pb={'30px'}>
      <Heading fontSize={'lg'} color={'red.600'}>
        Username or Password incorrect.
      </Heading>
    </Center>
  );

  return (
    <Flex
      h={'100%'}
      w={'100%'}
      align={'center'}
      justify={'center'}
      bgGradient='linear(to-t, #EBF3FD, #f3f0ff)'
      >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool features ✌️
          </Text>       
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
          {failedAuth ? authError : null}
          <form onSubmit={handleSubmit}>
            <FormControl id="email" >
              <FormLabel>Email address:</FormLabel>
              <Input mb={'20px'} type="email" value={values.email} name='username' autoComplete="email" onChange={handleChange} />
            </FormControl>
            <FormControl id="password" >
              <FormLabel>Password:</FormLabel>
              <Input type="password" value={values.password} name='password' autoComplete="current-password" onChange={handleChange} />
            </FormControl>
            <Stack spacing={10}>
              <Stack>
                <Link color={'blue.400'} href={'/password-reset-request'}>Forgot password?</Link>
              </Stack>
              
              <Button
                type='submit'
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}>
                {loading ? <Spinner speed='0.65s'/> : 'Sign in'}
              </Button>
              
              <Text align={'center'}>
                Don't have an account? <Link color={'blue.400'} href={'/register'}>Register</Link>
              </Text>
            </Stack>
          </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}