import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import user from '../data/user';
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useBreakpointValue,
  PopoverContent,
  Icon,
  Tag
} from '@chakra-ui/react';
import {
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { updateUser, updateAuth } from '../store/userSlice';

export default function TopNav() {
  const auth = useSelector((state) => state.user.isAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    user.logout().then(() => {
      dispatch(updateUser({}));
      dispatch(updateAuth(false));
      localStorage.setItem('loggedIn',false);
    })
  }

  const loggedIn = (
    <>
    <Tag 
      mr='20px'
      variant='subtle' 
      colorScheme='cyan'
      size='lg'
    >
      Beta
    </Tag>
    <Button
      as={'a'}
      fontSize={'sm'}
      fontWeight={400}
      variant={'link'}
      onClick={()=>{
        navigate('/account')
      }}
      >
      Account
    </Button>
    <Button
      ml={'20px'}
      as={'a'}
      fontSize={'sm'}
      fontWeight={400}
      variant={'link'}
      onClick={logout}
      >
      Log out
    </Button>
    </>
  )

  const loggedOut = (
    <>
      <Tag 
      mr='20px'
      variant='subtle' 
      colorScheme='cyan'
      size='lg'
    >
      Beta
    </Tag>
      <Button
        as={'a'}
        fontSize={'sm'}
        fontWeight={400}
        variant={'link'}
        href={'/login'}>
        Sign In
      </Button>
      <Button
        ml={'20px'}
        as={'a'}
        display={{ base: 'none', md: 'inline-flex' }}
        fontSize={'sm'}
        fontWeight={600}
        color={'white'}
        bg={'pink.400'}
        href={'/register'}
        _hover={{
          bg: 'pink.300',
        }}>
        Sign Up
      </Button>
    </>
  );

  return (
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        as="header"
        height={'60px'}
        width="100%"
        position="fixed"
        zIndex={200}
        boxShadow='md'
        maxW={'2000px'}
        mt={'-60px'}
        >
        
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
            VeoLabs.ai
          </Text>

          {/* <Image src={'/images/veolabs.png'} h='px'/> */}

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
        {auth ? loggedIn : loggedOut}
      </Flex>
  );
}

const DesktopNav = () => {
  const navigate = useNavigate();
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                onClick={() => {
                  navigate(navItem.href);
                }}
                p={2}
                //href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>
            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href }) => {
  return (
      <Link
        href={href}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'pink.400' }}
              fontWeight={500}>
              {label}
            </Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
  );
};

const NAV_ITEMS = [
  {
    label: 'Agents',
    href: '/agents',
  },
  {
    label: 'Models',
    href: '/models',
  },
  {
    label: 'Chat',
    href: '/generate/chat',
  },
  // {
  //   label: 'Templates',
  //   href: '/templates',
  // },
  {
    label: 'Text / Copy',
    href: '/generate/copy',
    // children: [
    //   {
    //     label: 'Chat interface',
    //     href: '#',
    //   },
    //   {
    //     label: 'Written content',
    //     href: '/generate/copy',
    //   },
    //   {
    //     label: 'Images',
    //     href: '/generate/image',
    //   },
    //   {
    //     label: 'Voice / audio',
    //     href: '/generate/voice',
    //   },
    // ],
  },
  {
    label: 'Voice',
    href: '/generate/voice',
  },
  {
    label: 'Image',
    href: '/generate/image',
  },
  {
    label: 'Documents',
    href: '/documents',
  },
  // {
  //   label: 'Batch',
  //   href: '/batch',
  // },
  // {
  //   label: 'Projects',
  //   href: '/projects',
  // },
  // {
  //   label: 'Knowledge',
  //   href: '/knowledge',
  // },
  
];