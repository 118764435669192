import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box } from "@chakra-ui/react";
import DomainManager from './DomainManager';

const SelectDomain = ({setDomain, domain}) => {
  const [domains, setDomains] = useState(['Loading domains...']);

  const addDomain = async (domain) => {
    try {
      const response = await fetch('/domains/scanDomain', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain }),
      });

      if (response.ok) {
        const result = await response.text();
        console.log(result);
        fetchDomains();
      } else {
        console.error('Error:', response.statusText);
      }
      return response;
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const fetchDomains = async () => {
    try {
      const response = await fetch('/domainsSitemap', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          // Add any required authorization headers here, e.g., a JWT token
          // 'Authorization': `Bearer ${yourJwtToken}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setDomains(result.length ? result : []);
        //setDomain(result.length ? domain : 'No domains available');
      } else {
        console.error('Error:', response.statusText);
        setDomains([]);
      }
    } catch (error) {
      console.error('Error:', error);
      setDomains([]);
    }
  };

  useEffect(() => {
    fetchDomains();
  }, []);

  return (
    <ChakraProvider>
      <Box pt={5}>
        <DomainManager
            domain={domain}
            setDomain={setDomain}
            fetchDomains={fetchDomains}
            domains={domains}
            addDomain={addDomain}
        />
      </Box>
    </ChakraProvider>
  );
};

export default SelectDomain;