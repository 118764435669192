import axios from 'axios'
import noUser from '../utils/HandleNoUser';
// import mixpanel from 'mixpanel-browser';
// Set this to a unique identifier for the user performing the event.
//mixpanel.identify('user123')
 
// Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
// mixpanel.track('Sign up', {
//   'Signup Type': 'Referral'
// })

const ENV = process.env.NODE_ENV;

const api_url = ENV === 'development' ? 'http://localhost:3000' : 'https://server.veolabs.ai'

const history = {
  getChatHistory: async () => {
    return await axios.get(
      `${api_url}/data/history/chat`,
      {withCredentials: true}
      ).then((history) => {
        if(history.data === 'no user'){
          noUser();
        } else {
          return history.data;
        }
    }).catch(err => console.log(err))
  },
  delChatHistory: async (conversationId) => {
    return await axios.get(
      `${api_url}/data/history/chatDel`,
      {
        headers: {
          // set your custom headers here
          'conversation-id': conversationId,
        },
        withCredentials: true
      }
    ).then((result) => {
      if(result.data === 'no user'){
        noUser();
      } else {
        return result.data;
      }
    }).catch(err => console.log(err))
  },
  getCopyHistory: async () => {
    return await axios.get(
      `${api_url}/data/history/copy`,
      {withCredentials: true}
      ).then((history) => {
        if(history.data === 'no user'){
          noUser();
        } else {
          return history.data;
        }
    }).catch(err => console.log(err))
  },
  delCopyHistory: async (conversationId) => {
    return await axios.get(
      `${api_url}/data/history/copyDel`,
      {
        headers: {
          // set your custom headers here
          'conversation-id': conversationId,
        },
        withCredentials: true
      }
    ).then((result) => {
      if(result.data === 'no user'){
        noUser();
      } else {
        return result.data;
      }
    }).catch(err => console.log(err))
  },
  getImageHistory: async () => {
    return await axios.get(
      `${api_url}/data/history/image`,
      {withCredentials: true}
      ).then((history) => {
        if(history.data === 'no user'){
          noUser();
        } else {
          return history.data;
        }
    }).catch(err => console.log(err))
  },
  delImageHistory: async (id) => {
    return await axios.get(
      `${api_url}/data/history/imageDel`,
      {
        headers: {
          // set your custom headers here
          'id': id,
        },
        withCredentials: true
      }
    ).then((result) => {
      if(result.data === 'no user'){
        noUser();
      } else {
        return result.data;
      }
    }).catch(err => console.log(err))
  },
  getVoiceHistory: async () => {
    return await axios.get(
      `${api_url}/data/history/voice`,
      {withCredentials: true}
      ).then((history) => {
        if(history.data === 'no user'){
          noUser();
        } else {
          return history.data;
        }
    }).catch(err => console.log(err))
  },
  delVoiceHistory: async (id,timestamp) => {
    return await axios.get(
      `${api_url}/data/history/voiceDel`,
      {
        headers: {
          // set your custom headers here
          'timestamp': timestamp,
          'id': id,
        },
        withCredentials: true
      }
    ).then((result) => {
      if(result.data === 'no user'){
        noUser();
      } else {
        return result.data;
      }
    }).catch(err => console.log(err))
  },
  getAgentHistory: async () => {
    return await axios.get(
      `${api_url}/data/history/agent`,
      {withCredentials: true}
      ).then((history) => {
        if(history.data === 'no user'){
          noUser();
        } else {
          return history.data;
        }
    }).catch(err => console.log(err))
  },
  delAgentHistory: async (id) => {
    return await axios.get(
      `${api_url}/data/history/agentDel`,
      {
        headers: {
          // set your custom headers here
          'id': id,
        },
        withCredentials: true
      }
    ).then((result) => {
      if(result.data === 'no user'){
        noUser();
      } else {
        return result.data;
      }
    }).catch(err => console.log(err))
  },
}

export default history;