import {
    Box,
    Container,
    Text,
    Button,
    Stack,
    Flex,
    Heading
  } from '@chakra-ui/react';
  import { useSelector } from 'react-redux';
  
  export default function Account() {

    const activeUser = useSelector((state) => state.user.activeUser);

    const { innerWidth: width} = window;

    if(width <= 900){
        return (
            <Flex
                justify={'center'}
                align={'center'}
                p={'20px'}
                >
                <Heading size={'sm'}>
                    Sorry we don't currently support screen sizes below 900px
                </Heading>
            </Flex>
        )
    }
    const emailLink = "mailto:andrew@veolabs.ai?subject=Feedback";
    
    const handleClick = () => {
        window.location.href=emailLink;
    }
  
    return (
    <Container
        pt='200px'
    >
        <Stack
        as={Box}
        textAlign={'center'}
        >
            <Text color={'gray.500'}>
                <strong>Email/Username: </strong>{activeUser.username}<br/><br/>
                <strong>Subscription: </strong>Free trial - Beta<br/><br/>
                <br/><br/><br/>
            </Text>
            <Text color={'gray.500'}>
                Please let us know if you have any questions / feedback
            </Text>
            <Stack
                direction={'column'}
                spacing={2}
                align={'center'}
                alignSelf={'center'}
                position={'relative'}
            >
                <Button
                    mt='30px'
                    colorScheme='blue'
                    rounded={'full'}
                    px={6}
                    onClick={handleClick}
                    _hover={{
                    bg: 'blue.400',
                    }}
                >
                    Contact
                </Button>
            </Stack>
        </Stack>
    </Container>  
    );
  }