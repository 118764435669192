import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateVeoWritercontent,
  updateVeoWriterOptions,
  updateAgentHistory
} from '../../store/contentSlice';
import {
  ChakraProvider,
  extendTheme,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter
} from "@chakra-ui/react";
import Loading from '../../components/Loading';
import history from '../../data/history';
import { FiDelete } from 'react-icons/fi';

const TableCmp = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const agentDocuments = useSelector((state) => state.content.agentHistory);

  const [loading, setLoading] = useState(true);
  const [localDocuments, setLocalDocuments] = useState([]);

  useEffect(() => {
    const fetchAgentHistory = async () => {
      const agentHistoryData = await history.getAgentHistory();
      dispatch(updateAgentHistory(agentHistoryData));
      setLoading(false);
    };
    fetchAgentHistory();
  }, [dispatch]);

  useEffect(() => {
    const labeledAgentDocuments = agentDocuments.map(doc => {
      return { ...doc, type: 'Agent', dateTime: doc.createdAt, modelName: doc.agent };
    });

    const cleanDocs = labeledAgentDocuments.filter((doc) => doc.title);

    setLocalDocuments(
      cleanDocs.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
    );
  }, [agentDocuments]);

  const handleClick = (doc) => {
    navigate('/agents/veowriter');
    dispatch(updateVeoWriterOptions(doc.settings));
    dispatch(updateVeoWritercontent({ action: 'overwrite', 'content': doc.data.content }));
    onClose();
  };

  const handleDelClick = (doc) => {
    history.delAgentHistory(doc._id);
    const newAgentHistory = agentDocuments.filter((existingDoc) => existingDoc._id !== doc._id);
    dispatch(updateAgentHistory(newAgentHistory));
  };

  const createDocumentList = (documents) => {
    return documents.map((doc, index) => {
      const options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      const dateTime = new Date(doc.dateTime);
      const formattedDateTime = dateTime.toLocaleDateString("en-US", options);

      return (
        <Tr _hover={{ bg: "gray.100", cursor: "pointer" }} key={index} h='50px' border='none'>
          <Td textAlign={'center'} onClick={() => { handleClick(doc); }}><Text as='b'>{doc.title}</Text></Td>
          <Td textAlign={'center'} onClick={() => { handleClick(doc); }}>{formattedDateTime}</Td>
          <Td textAlign={'center'}><Flex justify={'center'}><FiDelete color='#eb264f' fontSize='1.5rem' onClick={() => { handleDelClick(doc); }} /></Flex></Td>
        </Tr>
      );
    });
  };

  const RenderedList = createDocumentList(localDocuments);

  if (loading) {
    return (
      <Flex w={'100%'} h={'300px'} align={'center'} justify={'center'}>
        <Loading />
      </Flex>
    );
  }

  if (localDocuments.length === 0) {
    return (<div>No documents found. Anything you generate will automatically be saved here.</div>);
  }

  return (
    <Flex w='100%' direction='column'>
      <TableContainer w='100%' maxH={'650px'} overflowY={'auto'} mt={'10px'} border='1px solid #f8f8f8' borderRadius={'5px'} position='relative'>
        <Table size='sm' w='100%'>
          <Thead>
            <Tr>
              <Th textAlign={'center'}>Name</Th>
              <Th textAlign={'center'}>Created date</Th>
              <Th textAlign={'center'}>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>{RenderedList}</Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}

const newTheme = extendTheme({
  components: {
    Table: {
      parts: ['table', 'thead', 'th', 'tbody', 'tr'],
      baseStyle: {
        thead: {
          th: {
            backgroundColor: 'white',
            position: 'sticky',
            top: '0',
            zIndex: '1',
          }
        },
        tbody: {
          tr: {
            '&:nth-of-type(odd)': {
              background: "#fff",
            },
            '&:nth-of-type(even)': {
              backgroundColor: "#f8f8f8",
            },
            '&:hover': {
              backgroundColor: '#f1f3f5',
            }
          },
        }
      }
    }
  }
});

const AgentDocumentModal = ({ isOpen, onClose }) => (
  <ChakraProvider theme={newTheme}>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="90vw" width="60vw" maxHeight="80vh" height="60vh">
        <ModalHeader>Documents</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow={'scroll'}>
          <TableCmp onClose={onClose} />
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </ChakraProvider>
);

export default AgentDocumentModal;